import React from "react";
import { Link, graphql } from 'gatsby'
import loadable from "@loadable/component";
import Layout from "../components/layout";
import SEO from "../components/seo"
import { Container, Row, Col } from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";
import { homeValuation, instantValuation } from "../site/urls";
const BreadcrumbModule = loadable(() => import("../components/BreadcrumbModule/BreadcrumbModule"));
const ContentModule = loadable(() => import("../modules/content-render"));
const StaticTemplate = ({ data }, props) => {
    const PageData = data?.strapiPage
    return (
        <Layout popularSearch={PageData?.select_popular_search?.title}>
            <SEO title={PageData?.seo?.metaTitle ? PageData?.seo?.metaTitle : PageData?.title} description={PageData?.seo?.metaDescription ? PageData?.seo?.metaDescription : PageData?.title} />

            <div className="layout-padding-top static-page-layout">
             <div className="bottom-right-image-home">
                <div className="hover-block">
                <div className="block-elements">
                {/* <Link to={instantValuation}>Instant Online Valuation</Link>
                <div className="vertical-line"></div> */}
                <Link to={homeValuation}>Home Visit Valuation</Link>
                </div>
                </div>
                <div className="banner-img"></div>
             </div>
                <BreadcrumbModule choose_menu={PageData.choose_menu} pagename={PageData.title} />
                <div className="content-block content">
                    <Container>
                        <Row>
                            <Col xl={9}>
                            <ScrollAnimation animateIn="animate__slideInUp" duration={.5} animateOnce delay={100} offset={100}>
                                <h1>{PageData?.title}</h1>
                                </ScrollAnimation>
                                {PageData?.add_page_modules?.length > 0 && PageData.add_page_modules?.map((module, index) => {
                                    return (
                                        <ScrollAnimation animateIn="animate__slideInUp" duration={.5} animateOnce delay={(index+1) * 100} offset={100}>
                                            {module.strapi_component === "components.content-block" && <ContentModule Content={module.content?.data?.content} />}
                                            {module.select_module==="report_repair"&&
                                                <div>
                                                    <p>Our repairs reporting system</p>
                                                    <iframe loading="lazy" class="fix_flow wrapper lazyloaded" scrollbars="no" 
                                                    style={{border:"0px",width:"100%",height:"700px",overflow:"hidden", display:"block"}}
                                                    src="https://oliverstown.fixflo.com/issue/plugin" data-rocket-lazyload="fitvidscompatible" data-ll-status="loaded"></iframe>
                                                </div>
                                            }
                                        </ScrollAnimation>
                                    )
                                })}
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </Layout>
    )
}

export default StaticTemplate

export const query = graphql`
query ($page_id: String) {
    strapiPage(id: {eq: $page_id}) {
        ...PageFragment
        add_page_modules {
            ... on STRAPI__COMPONENT_COMPONENTS_CONTENT_BLOCK {
                strapi_component
                content {
                    data {
                      content
                    }
                  }
            }
            ... on STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE {
                __typename
                select_module
                title
              }
        }
    }
  }
`